import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { HashRouter as Router, Route } from 'react-router-dom'
import TransitionSwitch from 'react-router-transition-switch'
import Fader from 'react-fader'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import * as serviceWorker from './serviceWorker'
import { BookViewPage } from './Pages/BookViewPage'
import { CodeEntryPage } from './Pages/CodeEntryPage'
import { ServiceWorkerNotification } from './Components/ServiceWorkerNotification'

let theme = createMuiTheme()
theme = responsiveFontSizes(theme)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={1}>
        <Router>
          <TransitionSwitch
            render={({ children }) => (
              <Fader
                fadeInTransitionDuration={10}
                fadeOutTransitionTimingFunction="ease-out"
                fadeInTransitionTimingFunction="ease-out"
              >
                {children}
              </Fader>
            )}
          >
            <Route exact path="/">
              <CodeEntryPage />
            </Route>

            <Route path="/heft/:licenseCode">
              <BookViewPage />
            </Route>
          </TransitionSwitch>
        </Router>

        <ServiceWorkerNotification />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
