import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useBookStore, useLoadBook } from '../Stores/BookStore'
import { RecentBookCover } from '../Components/RecentBookCover'
import { useRecentBooksStore } from '../Stores/RecentBooksStore'

function Header() {
  return (
    <AppBar style={{ overflow: 'hidden' }} position="fixed">
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <img
              alt="Verlagslogo"
              src="/diko.png"
              style={{
                maxWidth: 100,
                borderRadius: '2px',
                backgroundColor: '#fafafa',
                marginTop: '4px',
              }}
            />
          </Grid>

          <Grid item>
            <Typography variant="h6">Digitale Hefte</Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

function CodeForm({ loadBook, localLoaded, setLocalLoaded }) {
  const prevCode = useBookStore((state) => state.license.code)
  const loaded = useBookStore((state) => state.loaded)
  const loading = useBookStore((state) => state.loading)

  const [code, setCode] = useState(prevCode)

  useEffect(() => {
    if (!loaded && prevCode) loadBook(prevCode, setLocalLoaded)
  }, [prevCode, loaded])

  return (
    <Paper>
      <Grid
        container
        direction="column"
        spacing={2}
        justify="center"
        alignContent="center"
      >
        <Grid item lg>
          <TextField
            autoFocus
            size="medium"
            variant="filled"
            value={code.trim()}
            label="Heft-Code"
            placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
            onChange={(e) => setCode(e.target.value.trim())}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!code}
            onClick={() => loadBook(code, setLocalLoaded)}
          >
            Heft Öffnen
          </Button>
        </Grid>
      </Grid>
      <Backdrop style={{ zIndex: 99999999 }} open={loading || localLoaded}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  )
}

export function CodeEntryPage() {
  const [localLoaded, setLocalLoaded] = useState(false)
  const loadBook = useLoadBook()

  const recentBooks = useRecentBooksStore((state) => state.books)

  return (
    <div>
      <Header />
      <Toolbar variant="dense" />

      <Container style={{ paddingTop: 32 }}>
        <CodeForm
          localLoaded={localLoaded}
          setLocalLoaded={setLocalLoaded}
          loadBook={loadBook}
        />
      </Container>

      <Container style={{ paddingTop: 32 }}>
        <Paper>
          {recentBooks.length > 0 && (
            <Typography
              style={{ paddingBottom: 16, paddingTop: 8, textAlign: 'center' }}
              variant="h4"
            >
              Gespeicherte Hefte
            </Typography>
          )}
          <Grid container spacing={3} justify="center" alignContent="center">
            {recentBooks.map((b) => (
              <Grid item key={b.code}>
                <RecentBookCover
                  book={b}
                  onClick={() => loadBook(b.code, setLocalLoaded)}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </div>
  )
}
