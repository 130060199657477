import create from 'zustand'
import produce from 'immer'

const LOCAL_STORAGE_KEY = 'books.v1'

function loadFromStorage() {
  try {
    // This is a good place to migrate, if necessary
    const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    if (stored) {
      return JSON.parse(stored)
    }
    return []
  } catch (e) {
    console.error('loadFromStorage:', e)
    return []
  }
}

export const useRecentBooksStore = create((set, get) => ({
  books: loadFromStorage(),

  // TODO: verify that the set() get() use is not a race condition

  addBook: (book) => {
    // If book is already in here, just mark it as active
    if (get().books.find((b) => b.code === book.code)) {
      set(
        produce((state) => {
          const found = state.books.find((b) => b.code === book.code)
          found.active = true
        }),
      )
    } else
      set(
        produce((state) => {
          state.books.push(book)
        }),
      )
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(get().books))
  },

  markExpired: (book) => {
    if (!book.title) return
    set(
      produce((state) => {
        let b = state.books.find((i) => i.code === book.code)
        if (!b) {
          state.books.push(book)
          b = book
        }
        b.active = false
      }),
    )
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(get().books))
  },

  removeBook: (book) => {
    set({ books: get().books.filter((b) => b.code !== book.code) })
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(get().books))
  },
}))
