import React, { useCallback } from 'react'
import './PdfViewer.scss'
import shallow from 'zustand/shallow'
import HTMLFlipBook from 'react-pageflip/src/pageflip'
import { IconButton } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useBookStore } from '../Stores/BookStore'
import { useWindowSize } from './Hooks'

const useStyles = makeStyles(() => ({
  controls: {
    position: 'fixed',
    'z-index': 1,
    paddingTop: '45vh',
    paddingBottom: '50vh',
    borderRadius: 0,
    backgroundColor: 'transparent !important',
  },
}))

function Controls() {
  const styles = useStyles()

  const pageFlip = useBookStore((state) => state.pageFlip)
  // pageFlip.getPageFlip().flip(newPage, 'top')
  const prev = useCallback(() => {
    if (pageFlip && pageFlip.getPageFlip())
      pageFlip.getPageFlip().flipPrev('top')
  }, [pageFlip])
  const next = useCallback(() => {
    if (pageFlip && pageFlip.getPageFlip())
      pageFlip.getPageFlip().flipNext('top')
  }, [pageFlip])

  return (
    <>
      <IconButton
        className={styles.controls}
        style={{ left: 0, paddingRight: '49%' }}
        onClick={prev}
      >
        <ArrowBackIos />
      </IconButton>

      <IconButton
        className={styles.controls}
        style={{ right: 0, paddingLeft: '49%' }}
        onClick={next}
      >
        <ArrowForwardIos />
      </IconButton>
    </>
  )
}

export function PdfViewer() {
  const [pages, url, shortName] = useBookStore(
    (state) => [state.book.pages, state.book.base_url, state.book.short_name],
    shallow,
  )

  const [screenWidth, screenHeight] = useWindowSize()
  const images = []
  for (let i = 0; i < pages; i++) {
    images.push(
      // eslint-disable-next-line react/no-array-index-key
      <img
        alt={`Seite ${i}`}
        src={`${url}/pages/${shortName}-${i}.png`}
        key={i}
      />,
    )
  }

  return (
    <div style={{ overflow: 'hidden', userSelect: 'none' }}>
      <Controls />
      <HTMLFlipBook
        width={1240}
        height={1754}
        minWidth={380}
        maxWidth={screenWidth * 0.45}
        minHeight={400}
        size="stretch"
        showCover
        swipeDistance={0}
        flippingTime={350}
        useMouseEvents={false}
        ref={(cmp) => {
          if (useBookStore.getState().pageFlip == null)
            useBookStore.setState({ pageFlip: cmp })
        }}
        onFlip={(e) => useBookStore.setState({ page: e.data })}
        onChangeOrientation={(e) =>
          useBookStore.setState({ orientation: e.data })
        }
        onInit={(e) => useBookStore.setState({ orientation: e.data.mode })}
        style={{ maxHeight: screenHeight * 0.9 }}
      >
        {images}
      </HTMLFlipBook>
    </div>
  )
}
