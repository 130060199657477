import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams, Redirect } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import QRCode from 'qrcode.react'
import shallow from 'zustand/shallow'
import { Fullscreen, FullscreenExit } from '@material-ui/icons'
import screenfull from 'screenfull'
import { Sidebar } from '../Components/Sidebar'
import { AudioPlayer } from '../Components/AudioPlayer'
import { PdfViewer } from '../Components/PdfViewer'
import { useBookStore } from '../Stores/BookStore'

const drawerWidth = 240
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  licenseText: {
    [theme.breakpoints.up('md')]: {
      display: 'initial !important',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: 0,
    maxWidth: 'calc(100%)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
}))

function Header({ code, handleDrawerToggle }) {
  const classes = useStyles()
  const theme = useTheme()
  const [title, schoolName, licensed] = useBookStore((state) => [
    state.book.title,
    state.school.name,
    state.license.active,
  ])

  useEffect(() => {
    document.title = `Diko Online - ${title}`
  }, [title])

  const [fullscreen, setFullscreen] = useState(false)

  return (
    <AppBar
      style={{
        overflow: 'hidden',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: licensed ? undefined : 'red',
      }}
      position="fixed"
    >
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                setFullscreen(!fullscreen)
                screenfull.toggle(document.documentElement, {
                  navigationUI: 'hide',
                })
              }}
            >
              {fullscreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Grid>
          <Grid item>
            <RouterLink to="/">
              <img
                alt="Verlagslogo"
                src="/diko.png"
                style={{
                  maxWidth: 100,
                  borderRadius: '2px',
                  backgroundColor: '#fafafa',
                  marginTop: '4px',
                }}
              />
            </RouterLink>
          </Grid>

          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>

          <Grid
            item
            className={classes.licenseText}
            style={{ marginLeft: 'auto', display: 'none', userSelect: 'none' }}
          >
            <Typography variant="body1">
              <em title={code}>Lizenziert für: </em>{' '}
              {licensed ? schoolName : 'Lizenz läuft bald ab!'}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export function BookViewPage() {
  const { licenseCode } = useParams()
  const [loaded, set] = useBookStore(
    (state) => [state.loaded, state.set],
    shallow,
  )

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const classes = useStyles()
  const theme = useTheme()
  const school = useBookStore((state) => state.school.name)

  // Handle direct URL access (without having gone through the button)
  // Check for !school to avoid this branch when going back to home page by clicking on the logo
  if (!loaded && !school) {
    set((state) => {
      // eslint-disable-next-line no-param-reassign
      state.license.code = licenseCode
    })
    return <Redirect to="/" />
  }
  return (
    <div>
      <Header code={licenseCode} handleDrawerToggle={handleDrawerToggle} />
      <Toolbar variant="dense" />
      {/* TODO : on mobile, this toolbar is not enough because of the line wrapping. I need a better solution there. */}

      <nav className="print-hidden">
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          onOpen={() => setMobileOpen(true)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Sidebar />
        </SwipeableDrawer>
      </nav>

      {/* Print view */}
      <div
        className="print-visible"
        style={{ paddingTop: '5cm', textAlign: 'center' }}
      >
        <QRCode
          style={{ marginLeft: '12%' }}
          includeMargin
          size={512}
          level="Q"
          value={window.location.toString()}
        />
        <Typography variant="body2">Code: {licenseCode}</Typography>
        <br />
        <Typography variant="body2">
          <em>Lizensiert für: </em>
        </Typography>
        <Typography variant="body1">{school}</Typography>
        <b>Schullogo</b>
      </div>

      <Container
        className={`${classes.content} print-hidden`}
        style={{ textAlign: 'center', maxHeight: '75vh' }}
      >
        <PdfViewer />
        <AudioPlayer />
      </Container>
    </div>
  )
}
